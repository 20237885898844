html {
    background: #2c2c2c;
    height: 100%;
}
body {
    background-color: rgb(247, 244, 237);
    font-family:
        Roboto,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: black;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

#root {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

.body-2 {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(255, 255, 255, 0.7);
}

.caption {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 166%;
    letter-spacing: 0.4px;
}

h1 {
    font-weight: 300;
    font-size: 6rem;
    line-height: 116.7%;
    letter-spacing: -1.5px;
}

h2 {
    font-weight: 300;
    font-size: 3.75rem;
    line-height: 120%;
    letter-spacing: -0.5px;
}

h3 {
    font-weight: 400;
    font-size: 3rem;
    line-height: 116.7%;
}

h4 {
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 123.5%;
    letter-spacing: 0.25px;
}

h5 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 133.4%;
}

h6 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 160%;
    letter-spacing: 0.15px;
}

/* only style scrollbar for desktop devices */
@media screen and (min-width: 1200px) {
    ::-webkit-scrollbar {
        height: 8px;
        width: 8px;
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: #404040;
        border-radius: 1ex;
        -webkit-border-radius: 1ex;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #4a4a4a;
    }

    ::-webkit-scrollbar-thumb:active {
        background: #4f4f4f;
    }

    ::-webkit-scrollbar-corner {
        background: #000;
    }
}
